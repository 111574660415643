import React from "react";
import "./AboutComponent1.style.scss";
import rightFish from "../../../assets/rightFish.png";
import founder from "../../../assets/founder.jpg";

const AboutComponent1 = () => {
  return (
    <section className="about1">
      <div className="container">
        <div className="content">
          <div className="leftContent">
            <div className="headingContainer">
              <h1 className="heading">About Us</h1>
              <div className="imageContainer">
                <img src={rightFish} alt="Rudra" />
              </div>
            </div>
            <div className="paragraph">
              <p>
                Established in 2009, Rudra Techno Feeds construction and
                commissioning was completed in merely nine months and marched
                ahead for production with the slogan Feed fish to feed you.
                Being an ISO 9001:2015 company we manufacture nutritionally
                balanced products that are well-researched and quality tested to
                help your fish and shrimp grow larger and stronger.
              </p>
              <p>
                With over 25 years of experience, our Managing Director Mr. R.
                Sridhar Varma has created a perfect blend of world-class
                machinery and a quality team. Spread over 13+ acres, the factory
                is capable of producing 1,00,000 MT of floating fish feed and
                20,000 MT of shrimp feed per annum. Our team follows a stringent
                process at every step of production. We check the color,
                moisture, and packaging conditions of the raw material to
                maintain consistency and high feed quality.
              </p>
            </div>
          </div>
          <div className="rightImage">
            <img src={founder} alt="Founder" />
            <p>R.Sridhar Varma, Founder</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutComponent1;
