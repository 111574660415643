/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import "./AboutComponent3.style.scss";
import gsap from "gsap";

const AboutComponent3 = () => {
  useEffect(() => {
    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#numberBox",
        toggleActions: "restart none none none",
        start: "top 50%",
      },
    });
    tl.from("#empl", {
      duration: 1,
      ease: "none",
      innerHTML: "0",
      roundProps: "innerHTML",
    });
    tl.add(
      gsap.from("#acre", {
        duration: 1,
        ease: "none",
        innerHTML: "0",
        roundProps: "innerHTML",
      }),
      0
    );
    tl.add(
      gsap.from("#tons", {
        duration: 1,
        ease: "none",
        innerHTML: "0",
        roundProps: "innerHTML",
      }),
      0
    );
    tl.add(
      gsap.from("#customers", {
        duration: 1,
        ease: "none",
        innerHTML: "0",
        roundProps: "innerHTML",
      }),
      0
    );
  }, []);
  return (
    <section className="about3">
      <div className="container">
        <div className="content" id="numberBox">
          <div className="box box1">
            <p className="number" id="empl">
              150
            </p>
            <p>Employees</p>
          </div>
          <div className="box box2">
            <p className="number" id="acre">
              13
            </p>
            <p>Acre Facility</p>
          </div>
          <div className="box box3">
            <p className="number" id="tons">
              11000
            </p>
            <p>Tons produce/ month</p>
          </div>
          <div className="box box4">
            <p className="number" id="customers">
              1000
            </p>
            <p>Satisfied customers</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutComponent3;
