import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./Modal.scss";

const Modal = ({ data, setTabIndex, tabIndex }) => {
  const { fish, smallFish, shrimp, polyculture } = data;
  return (
    <>
      {/* Fish Modal */}
      <div
        className="modal fade"
        id="fishModal"
        tabIndex="-1"
        aria-labelledby="fishModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="fishModalLabel">
                Protein Variations
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mt-4">
              <Tabs
                selectedIndex={tabIndex.bigFish}
                onSelect={(index) =>
                  setTabIndex((prevState) => ({ ...prevState, bigFish: index }))
                }
              >
                <TabList>
                  {fish.sizes.map((ele) => (
                    <Tab key={ele} className="react-tabs__tab custom_tab">
                      {ele}mm
                    </Tab>
                  ))}
                </TabList>
                {fish.sizes.map((ele) => (
                  <TabPanel key={ele}>
                    {fish.protien[`${ele}mm`].map((ele) => (
                      <p key={ele}>Protein {ele}</p>
                    ))}
                  </TabPanel>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {/* Small Fish Modal */}
      <div
        className="modal fade"
        id="smallFishModal"
        tabIndex="-1"
        aria-labelledby="smallFishModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="smallFishModalLabel">
                Protein Variations
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mt-4">
              <Tabs
                selectedIndex={tabIndex.smallFish}
                onSelect={(index) =>
                  setTabIndex((prevState) => ({
                    ...prevState,
                    smallFish: index,
                  }))
                }
              >
                <TabList>
                  {smallFish.sizes.map((ele) => (
                    <Tab key={ele} className="react-tabs__tab custom_tab">
                      {ele}mm
                    </Tab>
                  ))}
                </TabList>
                {smallFish.sizes.map((ele) => (
                  <TabPanel key={ele}>
                    {smallFish.protien[`${ele}mm`].map((ele) => (
                      <p key={ele}>Protein {ele}</p>
                    ))}
                  </TabPanel>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {/* Shrimp Modal */}
      <div
        className="modal fade"
        id="shrimpModal"
        tabIndex="-1"
        aria-labelledby="shrimpModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="shrimpModalLabel">
                Protein Variations
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mt-4">
              <Tabs
                selectedIndex={tabIndex.shrimp}
                onSelect={(index) =>
                  setTabIndex((prevState) => ({ ...prevState, shrimp: index }))
                }
              >
                <TabList>
                  {shrimp.sizes.map((ele) => (
                    <Tab key={ele} className="react-tabs__tab custom_tab">
                      {ele}mm
                    </Tab>
                  ))}
                </TabList>
                {shrimp.sizes.map((ele) => (
                  <TabPanel key={ele}>
                    {shrimp.protien[`${ele}mm`].map((ele) => (
                      <p key={ele}>Protein {ele}</p>
                    ))}
                  </TabPanel>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {/* Poly Culture Modal */}
      <div
        className="modal fade"
        id="polycultureModal"
        tabIndex="-1"
        aria-labelledby="polycultureModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="polycultureModalLabel">
                Protein Variations
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mt-4">
              <Tabs
                selectedIndex={tabIndex.polyculture}
                onSelect={(index) => {
                  setTabIndex((prevState) => ({
                    ...prevState,
                    polyculture: index,
                  }));
                }}
              >
                <TabList>
                  {polyculture.sizes.map((ele) => (
                    <Tab key={ele} className="react-tabs__tab custom_tab">
                      {ele}mm
                    </Tab>
                  ))}
                </TabList>
                {polyculture.sizes.map((ele) => (
                  <TabPanel key={ele}>
                    {polyculture.protien[`${ele}mm`].map((ele) => (
                      <p key={ele}>Protein {ele}</p>
                    ))}
                  </TabPanel>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
