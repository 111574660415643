import React from "react";
import "./HomeComponent2.style.scss";
import smallFish from "../../../assets/smallFish.png";

const FishComponent = () => {
  return (
    <div className="FishContainer" id="smallFish1">
      <div className="fish1">
        <img src={smallFish} alt="Rudra" />
      </div>
      <div className="fish2">
        <img src={smallFish} alt="Rudra" />
      </div>
      <div className="fish3">
        <img src={smallFish} alt="Rudra" />
      </div>
      <div className="fish4">
        <img src={smallFish} alt="Rudra" />
      </div>
      <div className="fish5">
        <img src={smallFish} alt="Rudra" />
      </div>
    </div>
  );
};

export default FishComponent;
