import React from "react";
import "./HomeComponent6.style.scss";
import Coral from "../../../assets/coral.png";
import rightCoral from "../../../assets/right_coral.png";
import semiCircle from "../../../assets/semi_circle.png";
import smallCircle from "../../../assets/small_circle.png";
import Star from "../../../assets/Star.png";

const HomeComponent5 = () => {
  return (
    <section className="bottom">
      <div className="container">
        <div className="blue_coral_bottom">
          <img src={Coral} alt="Blue Coral" />
        </div>
        <div className="right_coral_bottom">
          <img src={rightCoral} alt="Right Coral" />
        </div>
        <div className="semi_circle_bottom">
          <img src={semiCircle} alt="Right Coral" />
        </div>
        <div className="small_circle_bottom">
          <img src={smallCircle} alt="Right Coral" />
        </div>
        <div className="star_bottom">
          <img src={Star} alt="Right Coral" />
        </div>
      </div>
    </section>
  );
};

export default HomeComponent5;
