import React from "react";
import "./Navbar.styles.scss";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const NavItem = ({ title, children, link, type }) => {
  return type ? (
    <HashLink smooth to={`${link}`}>
      <div className="navItem">
        <p>{title}</p>
        <div className="icon">{children}</div>
      </div>
    </HashLink>
  ) : (
    <Link to={`${link}`}>
      <div className="navItem">
        <p>{title}</p>
        <div className="icon">{children}</div>
      </div>
    </Link>
  );
};

export default NavItem;
