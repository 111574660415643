import WhiteSpotVirus from "../assets/Diseases/White_spot_virus.jpg";
import TauraSyndromeVirus from "../assets/Diseases/taura_syndrome_virus.jpg";
import EpizooticUlcerativeSyndrome from "../assets/Diseases/epizootic_ulcerative_syndrome.jpg";
import Saprolegniasis from "../assets/Diseases/saprolegniasis.jpg";
import Argulosis from "../assets/Diseases/argulosis.jpg";

export const DiseasesData = [
  {
    title: "White Spot Virus",
    image: WhiteSpotVirus,
    description:
      "Distinctive white spots with a diameter of 0.5-2.0 mm in the carapace, a lethargic surface, and a decreased appetite in the cuticle cannot be scraped off with fingernails. The Source of this infection is pond water. Extract of brown macroalgae can reduce mortality of shrimp challenged with WSSV.",
  },
  {
    title: "Taura Syndrome Virus",
    image: TauraSyndromeVirus,
    description:
      "TSV is most likely transmitted by cannibalism of dead infected shrimp. Week Reddish shrimp with black spots. Eat excess with low appetite, outbreaks, and mortalities. Limit the use of transgenic animals as a means of disease control.",
  },
  {
    title: "Epizootic ulcerative syndrome (EUS)",
    image: EpizooticUlcerativeSyndrome,
    description:
      "Also known by the name Red Spot Disease can affect many species of fishes, the disease can be spotted when the fish exhibits red spots or deep ulcers. Highly infected fish should be removed and moving those with mild symptoms to high quality water can aid in reducing the damage.",
  },
  {
    title: "Saprolegniasis",
    image: Saprolegniasis,
    description:
      "This is a fungal infection caused by pathogenic bacteria or protozoan parasites. Affects the fish egg and juvenile fish in hatcheries with white or grey mycelial patches. Medicating the water with potassium permanganate is preferable.",
  },
  {
    title: "Argulosis (Carp lice)",
    image: Argulosis,
    description:
      "The parasite is seen moving on the skin surface, causing skin lesions with secondary bacterial infections, hemorrhagic spots, and ulcers. It is liable for epizootic outbreaks which cause the collapse of entire aquaculture operations. Screening and quarantine of incoming fish are recommended to minimize the risk of parasites.",
  },
];
