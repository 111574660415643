/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./HomeComponent4.style.scss";
import Vector from "../../../assets/Vector.png";
import galjoenImage from "../../../assets/galjoen.png";
import shrimpImage from "../../../assets/shrimp (2).png";

const TitleCard = ({ title, price, fish }) => {
  return (
    <div className="title_card">
      <span className="image">
        <img src={fish ? galjoenImage : shrimpImage} alt="" />
      </span>
      <p className="title_desc">
        {title} - {price}/Kg
      </p>
    </div>
  );
};

const HomeComponent4 = ({ fish, shrimp, liveData }) => {
  const [viewNumber, setViewNumber] = useState(2);
  const [view, setView] = useState(true);

  const handleChange = () => {
    if (view) {
      setViewNumber(liveData.length);
      setView((prevState) => !prevState);
    } else {
      setViewNumber(2);
      setView((prevState) => !prevState);
    }
  };

  return (
    <section className="home4">
      <div className="container">
        <div className="inner-container">
          <div className="title">
            <h1>Fish & Shrimp prices</h1>
            <span className="image">
              <img src={Vector} alt="" />
            </span>
          </div>
          <div className="live_price">
            {liveData.map(({ name, price, fish }, index) => {
              if (index < viewNumber) {
                return (
                  <TitleCard
                    title={name}
                    price={price}
                    fish={fish}
                    key={name}
                  />
                );
              } else {
                return null;
              }
            })}
          </div>
          <div className="data_number">
            {view ? (
              <>
                <span className="text">
                  {fish} fish & {shrimp} shrimp price available
                </span>
                <span>
                  <a onClick={handleChange}>Click to View</a>
                </span>
              </>
            ) : (
              <span>
                <a onClick={handleChange}> View Less</a>
              </span>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeComponent4;
