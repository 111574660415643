/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Menu from "../assets/menu.png";
import Close from "../assets/close.png";
import Logo from "../assets/logo.png";
import GreenCoral from "../assets/coral_menu.png";
import BlueCoral from "../assets/coral_menu1.png";
import { Link, useHistory } from "react-router-dom";

const MenuIcon = () => {
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();
  const handleClick = (e) => {
    e.preventDefault();
    setShowMenu((prevState) => !prevState);
  };
  const handleClickRoute = (name) => {
    setShowMenu((prevState) => !prevState);
    history.push(`/${name}`);
  };
  return (
    <>
      <div className="menuIcon" onClick={handleClick}>
        <img src={Menu} alt="Rudra" />
      </div>
      <div className={showMenu ? "showMenu_active showMenu" : "showMenu"}>
        <div className="header_container">
          <div className="logo_menu">
            <img src={Logo} alt="Rudra" />
          </div>
          <div className="closeIcon" onClick={handleClick}>
            <img src={Close} alt="Rudra" />
          </div>
        </div>
        <nav className="showMenu_navMenu">
          <Link className="showMenu_navItem" to="/">
            Home
          </Link>
          <a
            className="showMenu_navItem"
            onClick={() => handleClickRoute("about")}
          >
            About Us
          </a>
          <a
            className="showMenu_navItem"
            onClick={() => handleClickRoute("product")}
          >
            Products
          </a>
          <a
            className="showMenu_navItem"
            onClick={() => handleClickRoute("diseases")}
          >
            Diseases
          </a>
        </nav>
        <hr />
        <div className="contact_menuBar">
          <p className="head">Contact Us</p>
          <p className="email">info@rudratechnofeeds.com</p>
          <a className="number" href="tel:088162 51818">
            <p>08816 251818</p>
          </a>
          <p className="address">
            ASR Nagar, Bhimavaram, Andhra Pradesh - 534204 (Office)
          </p>
          <p className="address">
            Nallamadu, Andhra Pradesh - 534406 (Factory)
          </p>
        </div>
        <div className="image_menu">
          <div className="blue">
            <img src={BlueCoral} alt="Rudra" />
          </div>
          <div className="green">
            <img src={GreenCoral} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuIcon;
