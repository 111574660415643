/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import "./HomeComponent1.style.scss";
import Cork from "../../../assets/cork.png";
import Navbar from "../../Navbar/Navbar";
// import FishVideo from "../../../assets/iStock-1295435342.mp4";
import FishVideo from "../../../assets/rudraVideo.mp4";

const HomeComponent1 = () => {
  return (
    <section className="home1">
      <div className="container">
        <div className="video">
          <video className="videoTag" autoPlay loop muted>
            <source src={FishVideo} type="video/mp4" />
          </video>
        </div>
        <Navbar />
      </div>
      <div class="custom-shape-divider-bottom-1632391273">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default HomeComponent1;

{
  /* <div className="custom-shape-divider-bottom-1628566547">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className="shape-fill"
          ></path>
        </svg>
      </div> */
}
