import React from "react";
import { ReactComponent as Map } from "../../assets/Map.svg";
import "./common.style.scss";

const ContactComponent = () => {
  return (
    <section className="contact_common" id="contact">
      <div className="container">
        <div className="inner-container">
          <div className="content">
            <div className="address">
              <p className="heading">Contact Us</p>
              <p>info@rudratechnofeeds.com</p>
              <a className="phoneNumber" href="tel:088162 51818">
                <p>08816 251818</p>
              </a>
              <p>ASR Nagar, Bhimavaram, Andhra Pradesh - 534204 (Office)</p>
              <p>Nallamadu, Andhra Pradesh - 534406 (Factory)</p>
            </div>
          </div>
          <div className="map">
            <Map />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactComponent;
