import { useLocation } from "react-router-dom";
import "./App.scss";
import Header from "./components/Header/Header";
import Routes from "./Routes";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./ScrollToTop";

function App() {
  let location = useLocation();

  return (
    <>
      <HelmetProvider>
        <ScrollToTop />
        {location.pathname === "/" && <Header />}
        <Routes />
      </HelmetProvider>
    </>
  );
}

export default App;
