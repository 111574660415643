import React from "react";
import "./Navbar.styles.scss";

import { ReactComponent as About } from "../../assets/about.svg";
import { ReactComponent as Diseases } from "../../assets/disease.svg";
import { ReactComponent as Contact } from "../../assets/contact.svg";
import { ReactComponent as Product } from "../../assets/product.svg";
import NavItem from "./NavItem";

const NAVBAR_DATA = [
  {
    id: 1,
    title: "Products",
    link: "product",
    icon: <Product />,
  },
  {
    id: 2,
    title: "About Us",
    link: "about",
    icon: <About />,
  },
  {
    id: 3,
    title: "Diseases",
    link: "diseases",
    icon: <Diseases />,
  },
  {
    id: 4,
    type: "section",
    title: "Contact Us",
    link: "/#contact",
    icon: <Contact />,
  },
];

const Navbar = () => {
  return (
    <div className="navbar">
      {NAVBAR_DATA.map(({ id, title, icon, link, type }) => (
        <NavItem key={id} title={title} link={link} type={type}>
          {icon}
        </NavItem>
      ))}
    </div>
  );
};

export default Navbar;
