import React, { useEffect } from "react";
import "./HomeComponent3.style.scss";
import Food1 from "../../../assets/fishfood1.jpg";
import Food2 from "../../../assets/fishfood2.jpg";
import Food3 from "../../../assets/fishfood3.jpeg";
import FishRightSide from "../../../assets/fishRightSide.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const HomeComponent3 = () => {
  useEffect(() => {
    gsap.to("#FishContainer3", {
      x: 350,
      y: 0,
      duration: 2.5,
      scrollTrigger: {
        trigger: "#FishContainer3",
        toggleActions: "restart none none none",
        start: "top 40%",
      },
    });
  }, []);
  return (
    <section className="home3">
      <div className="container">
        <div className="inner-container">
          <div className="image-container">
            <div className="image_inner_container">
              <div className="image1">
                <img src={Food1} alt="Food1" />
              </div>
              <div className="image3">
                <img src={Food2} alt="Food2" />
              </div>
            </div>
            <div className="image2">
              <img src={Food3} alt="Food3" />
            </div>
          </div>
          <div className="content">
            <h2>Affordable Yet Effective</h2>
            <p>
              Loaded with accurate nutrients and required supplements, our
              products help maintain the mineral balance of the pond thereby
              promoting desired productivity.
            </p>
            <p>
              At Rudra Techno Feeds we deliver supreme quality feed, formulated
              with essential amino acids, omega-3 fatty acids, minerals and
              ensure it is affordable by aqua farmers.
            </p>
            <button className="btn">
              <Link to="/product">Our Products </Link>
            </button>
          </div>
        </div>
        <div id="FishContainer3">
          <div className="smallFishContainer" id="smallFishContainer">
            <img src={FishRightSide} alt="Rudra" />
          </div>
          <div className="bigFishContainer">
            <div className="bigFish">
              <img src={FishRightSide} alt="Rudra" />
            </div>
            <div className="smallFish1">
              <img src={FishRightSide} alt="Rudra" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeComponent3;
