import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ProductCard from "../ProductCard/ProductCard";
import "./ProductTab.scss";

const ProductTab = ({ data, tabIndex, setTabIndex }) => {
  return (
    <div className="container">
      <Tabs>
        <TabList>
          <Tab>FISH</Tab>
          <Tab>SHRIMP</Tab>
          <Tab>POLYCULTURE</Tab>
        </TabList>
        <TabPanel>
          <ProductCard
            fish={true}
            data={data.fish}
            tabIndex={tabIndex.bigFish}
            setTabIndex={setTabIndex}
          />
          <ProductCard
            fish={true}
            data={data.smallFish}
            tabIndex={tabIndex.smallFish}
            setTabIndex={setTabIndex}
          />
        </TabPanel>
        <TabPanel>
          <ProductCard
            shrimp={true}
            data={data.shrimp}
            tabIndex={tabIndex.shrimp}
            setTabIndex={setTabIndex}
          />
        </TabPanel>
        <TabPanel>
          <ProductCard
            fish={true}
            shrimp={true}
            data={data.polyculture}
            tabIndex={tabIndex.polyculture}
            setTabIndex={setTabIndex}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default ProductTab;
