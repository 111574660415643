import React, { useEffect } from "react";
import "./HomeComponent5.style.scss";
import { ReactComponent as Map } from "../../../assets/Map.svg";
import LeftFish from "../../../assets/leftFish.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const HomeComponent4 = () => {
  useEffect(() => {
    gsap.to("#leftFishContainer", {
      x: -200,
      y: 0,
      duration: 1.5,
      scrollTrigger: {
        trigger: "#leftFishContainer",
        toggleActions: "restart none none none",
        start: "top 80%",
      },
    });
  }, []);
  return (
    <section className="contact" id="contact">
      <div className="container">
        <div className="inner-container">
          <div className="content">
            <div className="address">
              <p className="heading">Contact Us</p>
              <p>info@rudratechnofeeds.com</p>
              <a className="phoneNumber" href="tel:088162 51818">
                <p>08816 251818</p>
              </a>
              <p>ASR Nagar, Bhimavaram, Andhra Pradesh - 534204 (Office) </p>
              <p>Nallamadu, Andhra Pradesh - 534406 (Factory)</p>
            </div>
          </div>
          <div className="map">
            <Map />
          </div>
        </div>
        <div className="leftFishContainer" id="leftFishContainer">
          <img src={LeftFish} alt="Rudra" />
          <div className="leftFishSmall">
            <img src={LeftFish} alt="Rudra" />
          </div>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1632391273">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default HomeComponent4;
