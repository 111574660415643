import React from "react";
import AboutComponent1 from "../components/About/AboutComponent1/AboutComponent1";
import AboutComponent2 from "../components/About/AboutComponent2/AboutComponent2";
import AboutComponent3 from "../components/About/AboutComponent3/AboutComponent3";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>Rudra Feeds | About</title>
      </Helmet>
      <AboutComponent1 />
      <AboutComponent2 />
      <AboutComponent3 />
    </>
  );
};

export default AboutUs;
