import React from "react";
import { Route, useLocation } from "react-router-dom";
import ContactComponent from "./components/Common/ContactComponent";
import Footer from "./components/Footer/Footer";
import Header2 from "./components/Header2/Header2";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Diseases from "./pages/Diseases";
import Home from "./pages/Home";
import Product from "./pages/Product";

const Routes = () => {
  let location = useLocation();
  return (
    <>
      {!(location.pathname === "/") && <Header2 />}
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={AboutUs} />
      <Route exact path="/product" component={Product} />
      <Route exact path="/diseases" component={Diseases} />
      <Route exact path="/contact" component={Contact} />
      {!(location.pathname === "/") && <ContactComponent />}
      {!(location.pathname === "/") && <Footer />}
    </>
  );
};

export default Routes;
