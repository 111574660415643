/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./HomeComponent2.style.scss";
import Cork from "../../../assets/cork.png";
import smallFish from "../../../assets/smallFish.png";
import fishCork from "../../../assets/fishCork.png";
import Rupee from "../../../assets/rupee.png";
import SmallRupee from "../../../assets/rupeeSmall.png";
import Coral from "../../../assets/coral.png";
import rightCoral from "../../../assets/right_coral.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import FishComponent from "./ImageComponent";
import { ReactComponent as BigCircle } from "../../../assets/Big_Circle.svg";
import { ReactComponent as SmallCircle } from "../../../assets/Small_Oval.svg";
import { ReactComponent as BigCircle2 } from "../../../assets/Big_Circle.svg";
import { useMediaQuery } from "../../useMedia";

gsap.registerPlugin(ScrollTrigger);

const HomeComponent2 = () => {
  const [fishImage, setFishImage] = useState(smallFish);
  const [activeFeed, setActiveFeed] = useState(true);
  const [activeFish, setActiveFish] = useState(false);
  const [activeMoney, setActiveMoney] = useState(false);

  useEffect(() => {
    var tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 2,
      scrollTrigger: {
        trigger: "#cork",
        start: "top 50%",
      },
    });
    tl.from("#cork", { y: -175, duration: 1 })
      .to("#cork", {
        xPercent: 250,
        opacity: 0,
        duration: 2,
        onStart: () => {
          setFishImage(smallFish);
          setActiveFeed(true);
          setActiveFish(false);
          setActiveMoney(false);
        },
        onComplete: () => {
          setFishImage(fishCork);
          setActiveFeed(false);
          setActiveFish(true);
        },
      })
      .to("#bigFish", {
        opacity: 0,
        duration: 2,
      })
      .to("#rupee2", {
        scale: 1.5,
        opacity: 1,
        duration: 2,
        onComplete: () => {},
      });
    tl.add(
      gsap.to("#smallFish1", {
        opacity: 1,
        duration: 2,
        scale: 1.5,
        onComplete: () => {
          setActiveMoney(true);
          setActiveFish(false);
        },
      }),
      3
    );
    tl.add(
      gsap.to("#rupee1", {
        opacity: 0,
        duration: 2,
      }),
      5
    );

    gsap.from(".topCircle", {
      x: -25,
      y: 200,
      duration: 2,
      scrollTrigger: {
        trigger: ".topCircle",
        toggleActions: "restart none none none",
        start: "top 40%",
      },
    });

    gsap.from(".bigcircle2", {
      x: -25,
      y: 300,
      duration: 2,
      scrollTrigger: {
        trigger: ".bigcircle2",
        toggleActions: "restart none none none",
        start: "top 80%",
      },
    });
    return () => {
      tl.kill();
    };
  }, []);

  return (
    <section className="home2">
      <div className="container">
        <div className="inner-container">
          <div className="card-container">
            <div className="card1">
              <div className="image" id="cork">
                <img src={Cork} alt="Feed" />
              </div>
              <div className={`title ${activeFeed ? "active" : ""}`}>
                <p>feed</p>
              </div>
            </div>
            <div className="card2">
              <div className="image">
                <div className="bigfishcontainer" id="bigFish">
                  <img src={fishImage} alt="Feed" className="bigFish1" />
                  <img src={fishImage} alt="Feed" className="bigFish2" />
                </div>
                <FishComponent />
              </div>
              <div className={`title ${activeFish ? "active" : ""}`}>
                <p>fish to</p>
              </div>
            </div>
            <div className="card3">
              <div className="image">
                <div className="image1" id="rupee1">
                  <img src={SmallRupee} alt="Feed" />
                </div>
                <div className="image2" id="rupee2">
                  <img src={Rupee} alt="Feed" />
                </div>
              </div>
              <div className={`title ${activeMoney ? "active" : ""}`}>
                <p>feed you</p>
              </div>
            </div>
          </div>
          <p className="content">
            Rudra Techno Feeds, an excellent choice for aquafarming, we
            manufacture a high-quality feed produced with world-class
            technology. With Rudra Techno Feeds, your fish and shrimp will have
            a low feed conversion ratio (FCR) but high growth. Our feed’s
            balanced nutritional solutions help to increase the yield.
          </p>
          <p className="content2">"every extra gram adds up to your revenue"</p>
        </div>
        <div className="blue-coral">
          <img src={Coral} alt="Blue Coral" />
        </div>
        <div className="right-coral">
          <img src={rightCoral} alt="Right Coral" />
        </div>
        <div className="topCircle">
          <BigCircle className="bigcircle" />
          <SmallCircle className="smallcircle" />
        </div>
        <BigCircle2 className="bigcircle2" />
      </div>
      <div class="custom-shape-divider-bottom-1632391273">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default HomeComponent2;
