import React from "react";
import Coral from "../../assets/coral.png";
import rightCoral from "../../assets/right_coral.png";
import semiCircle from "../../assets/semi_circle.png";
import smallCircle from "../../assets/small_circle.png";
import Star from "../../assets/Star.png";
import rightFish from "../../assets/rightFish.png";
import "./Footer.style.scss";

const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <div className="blue_coral_bottom">
          <img src={Coral} alt="Blue Coral" />
        </div>
        <div className="right_coral_bottom">
          <img src={rightCoral} alt="Right Coral" />
        </div>
        <div className="semi_circle_bottom">
          <img src={semiCircle} alt="Right Coral" />
        </div>
        <div className="small_circle_bottom">
          <img src={smallCircle} alt="Right Coral" />
        </div>
        <div className="star_bottom">
          <img src={Star} alt="Right Coral" />
        </div>
        <div className="rightFishContainer">
          <img src={rightFish} alt="" />
        </div>
        <div className="rightSmallFishContainer">
          <img src={rightFish} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Footer;
