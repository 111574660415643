import React from "react";
import HomeComponent1 from "../components/Home/HomeComponent1/HomeComponent1";
import HomeComponent2 from "../components/Home/HomeComponent2/HomeComponent2";
import HomeComponent3 from "../components/Home/HomeComponent3/HomeComponent3";
import HomeComponent4 from "../components/Home/HomeComponent4/HomeComponent4";
import HomeComponent5 from "../components/Home/HomeComponent5/HomeComponent5";
import HomeComponent6 from "../components/Home/HomeComponent6/HomeComponent6";
import { Helmet } from "react-helmet-async";
import { LIVE_DATA } from "./data";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Rudra Feeds | Home</title>
      </Helmet>
      <HomeComponent1 />
      <HomeComponent2 />
      <HomeComponent3 />
      {LIVE_DATA.live ? (
        <HomeComponent4 fish={3} shrimp={10} liveData={LIVE_DATA.live_data} />
      ) : null}
      <HomeComponent5 />
      <HomeComponent6 />
    </>
  );
};

export default Home;
