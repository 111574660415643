import React from "react";
import "./Header2.style.scss";
import Logo from "../../assets/logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ReactComponent as ActiveSvg } from "../../assets/active.svg";
import MenuIcon from "../MenuIcon";

const Header2 = () => {
  const location = useLocation();

  return (
    <section className="header2">
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="Rudra Logo" />
          </Link>
        </div>
        <div className="menu">
          <ul>
            <li>
              <Link to="/">
                <span>Home</span>
                <ActiveSvg />
              </Link>
            </li>
            <li>
              <NavLink to="/product">
                <span>Products</span>
                <ActiveSvg />
              </NavLink>
            </li>
            <li>
              <NavLink to="/about">
                <span>About Us</span>
                <ActiveSvg />
              </NavLink>
            </li>
            <li>
              <NavLink to="/diseases">
                <span>Diseases</span>
                <ActiveSvg />
              </NavLink>
            </li>
            <li>
              <HashLink smooth to={`${location.pathname}#contact`}>
                <span>Contact Us</span> <ActiveSvg />
              </HashLink>
            </li>
          </ul>
        </div>
        <MenuIcon />
      </div>
    </section>
  );
};

export default Header2;
