/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./DiseasesCard.style.scss";
import { useMediaQuery } from "../useMedia";

const DiseasesCard = ({ data }) => {
  const { image, title, description } = data;

  const media768 = useMediaQuery("(max-width:768px)");
  const media576 = useMediaQuery("(max-width:576px)");

  return (
    <div className="diseasescard">
      <div className="container">
        <div className="image">
          <img src={image} alt="Rudra" />
        </div>
        <div className="content">
          <h4>{title}</h4>
          {!media768 ? (
            <>
              <p>{description}</p>
              <a>View Details</a>{" "}
            </>
          ) : media576 ? (
            <p>
              {description.slice(0, 100)}
              <a>View Details</a>
            </p>
          ) : (
            <p>
              {description.slice(0, 200)}
              <a>View Details</a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DiseasesCard;
