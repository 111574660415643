import Image1_4mm from "../assets/Feed/4mm.jpg";
import Image2_4mm from "../assets/Feed/4mm 1.jpg";
import Image3_4mm from "../assets/Feed/4mm 2.jpg";
import Image4_4mm from "../assets/Feed/4mm 3.jpg";
import Image1_3mm from "../assets/Feed/3mm 1.jpg";
import Image2_3mm from "../assets/Feed/3mm 2.jpg";
import Image3_3mm from "../assets/Feed/3mm 3.jpg";
import Image4_3mm from "../assets/Feed/3mm 4.jpg";
import Image1_2mm from "../assets/Feed/2mm 1.jpg";
import Image2_2mm from "../assets/Feed/2mm 2.jpg";
import Image3_2mm from "../assets/Feed/2mm 3.jpg";
import Image1_15mm from "../assets/Feed/2mm 1.jpg";
import Image2_15mm from "../assets/Feed/2mm 1.jpg";
import Image3_15mm from "../assets/Feed/2mm 1.jpg";
import Image1_1mm from "../assets/Feed/1mm 1.jpg";
import Image2_1mm from "../assets/Feed/1mm 2.jpg";
import Image3_1mm from "../assets/Feed/1mm 3.jpg";
import Image1_06mm from "../assets/Feed/0.6mm 1.jpg";
import Image2_06mm from "../assets/Feed/0.6mm 2.jpg";
import Image3_06mm from "../assets/Feed/0.6mm 3.jpg";
import Image1_shrimp from "../assets/Feed/Vannamei shrimp feed 1.jpg";
import Image2_shrimp from "../assets/Feed/Vannamei shrimp feed 2.jpg";
import Image3_shrimp from "../assets/Feed/Vannamei shrimp feed 3.jpg";
import Image1_poly from "../assets/Feed/Poly feed 1.jpg";
import Image2_poly from "../assets/Feed/Poly feed 2.jpg";
import ImageBag_4mm from "../assets/Feed/4mm_bag.jpg";
import ImageBag_3mm from "../assets/Feed/3mm_bag.jpg";
import ImageBag_2mm from "../assets/Feed/2mm_bag.jpg";
import ImageBag_15mm_bag from "../assets/Feed/1@5mm_bag.jpg";
import ImageBag_01mm_bag from "../assets/Feed/1mm_bag.jpg";
import ImageBag_06mm_bag from "../assets/Feed/0@6mm_bag.jpg";
import ImageBag_polyfeed from "../assets/Feed/polyfeed_bag.png";

export const PRODUCT_DATA = {
  fish: {
    title: "bigFish",
    name: "Floating fish Feed",
    desc: "Our floating fish feed is designed to provide the right nutrients like essential amino acids  and Omega fatty acids that help maintain the nutritional balance.",
    sizes: [4, 3, 2],
    id: "#fishModal",
    protien: {
      "4mm": [32, 30, 28, 24, 20],
      "3mm": [32, 30, 28, 24, 20],
      "2mm": [32, 30, 28],
    },
    images: {
      "4mm": [Image1_4mm, Image2_4mm, Image3_4mm, ImageBag_4mm],
      "3mm": [Image1_3mm, Image2_3mm, Image3_3mm, ImageBag_3mm],
      "2mm": [Image1_2mm, Image2_2mm, Image3_2mm, ImageBag_2mm],
    },
  },
  smallFish: {
    title: "smallFish",
    name: "Floating fish Feed",
    desc: "Our floating fish feed is designed to provide the right nutrients like essential amino acids  and Omega fatty acids that help maintain the nutritional balance.",
    sizes: [1.5, 1, 0.6],
    id: "#smallFishModal",
    protien: {
      "1.5mm": [32],
      "1mm": [32, 35],
      "0.6mm": [40],
    },
    images: {
      "1.5mm": [Image1_15mm, Image2_15mm, Image3_15mm, ImageBag_15mm_bag],
      "1mm": [Image1_1mm, Image2_1mm, Image3_1mm, ImageBag_01mm_bag],
      "0.6mm": [Image1_06mm, Image2_06mm, Image3_06mm, ImageBag_06mm_bag],
    },
  },
  shrimp: {
    title: "shrimp",
    name: "Shrimp Feed",
    desc: "Specially formulated with the right essential Nutrients mix of  Amino acids and Omega 3 fatty acids to help shrimp obtain better growth with better FCR.",
    sizes: [1.2, 1.6, 1.8],
    id: "#shrimpModal",
    protien: {
      "1.2mm": [36],
      "1.6mm": [36],
      "1.8mm": [36],
    },
    images: {
      "1.2mm": [Image1_shrimp, Image2_shrimp, Image3_shrimp, Image1_shrimp],
      "1.6mm": [Image1_shrimp, Image2_shrimp, Image3_shrimp, Image1_shrimp],
      "1.8mm": [Image1_shrimp, Image2_shrimp, Image3_shrimp, Image1_shrimp],
    },
  },
  polyculture: {
    title: "polyculture",
    name: "Polyculture Feed",
    desc: "Our High-quality Poly feed is the best choice for fish and shrimps during polyculture farming",
    sizes: [2],
    id: "#polycultureModal",
    protien: {
      "2mm": [28, 20],
    },
    images: {
      "2mm": [Image1_poly, Image2_poly, Image2_poly, ImageBag_polyfeed],
    },
  },
};
