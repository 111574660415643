import React from "react";
import DiseasesCard from "../components/Diseases/DiseasesCard";

import { Helmet } from "react-helmet-async";
import { DiseasesData } from "./DiseasesData";

const Diseases = () => {
  console.log(DiseasesData);
  return (
    <>
      <Helmet>
        <title>Rudra Feeds | Diseases</title>
      </Helmet>
      <section className="container">
        {DiseasesData.map((data) => (
          <DiseasesCard data={data} />
        ))}
      </section>
    </>
  );
};

export default Diseases;
