export const LIVE_DATA = {
  live: false,
  live_data: [
    {
      name: "Roopchand",
      price: 200,
      fish: true,
    },
    {
      name: "Pangasius",
      price: 200,
      fish: true,
    },
    {
      name: "IMC",
      price: 200,
      fish: true,
    },
    {
      name: "100 Shrimps",
      price: 200,
      fish: false,
    },
    {
      name: "90 Shrimps",
      price: 200,
      fish: false,
    },
    {
      name: "80 Shrimps",
      price: 200,
      fish: false,
    },
    {
      name: "70 Shrimps",
      price: 200,
      fish: false,
    },
    {
      name: "60 Shrimps",
      price: 200,
      fish: false,
    },
    {
      name: "50 Shrimps",
      price: 200,
      fish: false,
    },
    {
      name: "40 Shrimps",
      price: 200,
      fish: false,
    },
    {
      name: "30 Shrimps",
      price: 200,
      fish: false,
    },
  ],
};
