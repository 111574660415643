/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./ProductCard.scss";
import galjoenImage from "../../../assets/galjoen.png";
import shrimpImage from "../../../assets/shrimp (2).png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const ProductCard = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { fish, shrimp, data, tabIndex, setTabIndex } = props;
  const { name, desc, sizes, id, title, protien, images } = data;
  // const [value, setValue] = useState(protien[`${sizes}mm`].length);
  // console.log(protien[`${sizes}[${tabIndex}mm]`].length);
  // console.log(tabIndex);
  // console.log(`${sizes[tabIndex]}mm`);
  // console.log(images[`${sizes[tabIndex]}mm`]);
  // console.log(protien[`${sizes[tabIndex]}mm`].length);
  const productClick = (index) => {
    setActiveIndex(index);
  };
  return (
    <div className="product_item">
      <div className="container">
        <div className="image">
          <div className="main_image">
            <img
              src={images[`${sizes[tabIndex]}mm`][activeIndex]}
              alt="Product"
            />
          </div>
          <div className="sub_image">
            {images[`${sizes[tabIndex]}mm`].map((item, index) => (
              <div className="image1" onClick={() => productClick(index)}>
                <img src={item} alt="Product" />
              </div>
            ))}
          </div>
        </div>
        <div className="content">
          <h4>
            {name}
            {fish ? (
              <span>
                <img src={galjoenImage} alt="Rudra" />
              </span>
            ) : null}
            {shrimp ? (
              <span>
                <img src={shrimpImage} alt="Rudra" />
              </span>
            ) : null}
          </h4>
          <p>{desc}</p>
        </div>
        <div className="sizes">
          <h5>Pillet size</h5>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => {
              setTabIndex((prevState) => ({
                ...prevState,
                [title]: index,
              }));
            }}
          >
            <TabList>
              {sizes.map((ele) => (
                <Tab key={ele} className="react-tabs__tab custom_tab">
                  {ele}mm
                </Tab>
              ))}
            </TabList>
          </Tabs>
          <p className="protein_variation">
            <span>
              {protien[`${sizes[tabIndex]}mm`].length} protein variations
              available
            </span>
            <a className="view" data-bs-toggle="modal" data-bs-target={id}>
              Click to View
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;

{
  /* {sizes.map((ele, index) => (
              <div
                className={`size ${active ? "active" : ""}`}
                key={ele}
                onClick={() => setTabIndex(index)}
              >
                {ele}mm
              </div>
            ))} */
}
