import React, { useState } from "react";
import ProductTab from "../components/Product/ProductComponent/ProductTab";
import { Helmet } from "react-helmet-async";
import Modal from "../components/Modal/Modal";
import { ScrollToTopOnMount } from "../ScrollToTop";
import { PRODUCT_DATA } from "./ProductData";

const Product = () => {
  const [tabIndex, setTabIndex] = useState({
    bigFish: 0,
    smallFish: 0,
    shrimp: 0,
    polyculture: 0,
  });

  return (
    <>
      <Helmet>
        <title>Rudra Feeds | Products</title>
      </Helmet>
      <ScrollToTopOnMount />
      <ProductTab
        data={PRODUCT_DATA}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
      />
      <Modal
        data={PRODUCT_DATA}
        setTabIndex={setTabIndex}
        tabIndex={tabIndex}
      />
    </>
  );
};

export default Product;
